import { proxyHeaders } from '../../next/helpers/monitoring'

export const gqlToOperationName = (tag) => {
    if (
        tag.definitions &&
        tag.definitions[0] &&
        tag.definitions[0].name &&
        tag.definitions[0].name.value
    ) {
        return tag.definitions[0].name.value
    }
    return null
}

export const gqlToQuery = (tag) => tag.loc.source.body

export const gqlFetch = async ({ url, query, variables }, req) => {
    const xHeaders = proxyHeaders(req?.headers)
    const headers = {
        'Content-Type': 'application/json',
        ...xHeaders,
    }

    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query: gqlToQuery(query),
            operationName: gqlToOperationName(query),
            variables,
        }),
    })

    if (response.status >= 500) {
        console.error({ status: response.status })
        throw response
    }

    if (response.status >= 400) {
        const result = await response.json()
        console.error({ errors: result?.errors })
        throw response
    }

    return await response.json()
}

export const gqlKey = ({ query, variables }) => {
    return JSON.stringify({
        variables,
        operation: gqlToOperationName(query),
    })
}
