import { gql } from 'urql'

export const ShoppingAreaFragment = gql`
    fragment ShoppingArea on ShoppingAreaType {
        data
        slug
        deliveryCosts
        name
        description
        brand
        bigHeader
        payoff
        published
        newsletter
        facebookUrl
        twitterUrl
        instagramUrl
        googleTagManager
        analytics
        themes
        geocode
        openGraphTitle
        openGraphSitename
        openGraphDescription
        openGraphImage
        sitemapUrl
        domains
        entrepreneurStories {
            id
            owner
            jobDescription
            quote
            intro
            text
            photo
            locationId
            locationTitle
            shoppingAreaSlugs
        }
    }
`

export const addEmailToList = gql`
    mutation addEmailToList(
        $email: String!
        $name: String!
        $shoppingAreaSlug: String!
    ) {
        addEmailToList(
            email: $email
            name: $name
            shoppingAreaSlug: $shoppingAreaSlug
        ) {
            ok
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
        }
    }
`

export const shoppingAreaBySlug = gql`
    ${ShoppingAreaFragment}
    query ShoppingArea($slug: String!) {
        shoppingArea(slug: $slug) {
            ...ShoppingArea
        }
    }
`

const shoppingAreaByDomain = gql`
    ${ShoppingAreaFragment}
    query ShoppingAreaByDomain($domain: String!) {
        shoppingAreaByDomain(domain: $domain) {
            ...ShoppingArea
        }
    }
`

export default shoppingAreaByDomain
