import Link from 'next/link'
import Trans from '../../../../shared/components/trans'

const Invite = () => (
    <li>
        <div className="search-results-item-invite">
            <h4>
                <Trans dutch label="Meld uw winkel aan" />
            </h4>
            <p>
                <Trans
                    label="Meld uw winkel snel en eenvoudig aan, voeg producten toe en word direct online vindbaar!"
                    dutch
                />
            </p>
            <Link href="/registreer">
                <a className="btn btn-success">
                    <Trans label="Winkel aanmelden" dutch />
                </a>
            </Link>
        </div>
    </li>
)

export default Invite
