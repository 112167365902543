import { t } from '@zupr/i18n'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'

import { ReactComponent as Triangle } from '../../../../svg/triangle.svg'

import '../../../../scss/react/components/small-box.scss'
import '../../../../scss/react/frontoffice/grid-list.scss'
import '../../../../scss/react/frontoffice/grid-page.scss'

interface Props {
    children: React.ReactNode
    title?: React.ReactNode
    className?: string
    fullWidth?: boolean
    swapOrder?: boolean
    collapsable?: boolean
}

const GridBlock = ({
    className,
    fullWidth,
    children,
    title,
    swapOrder,
    collapsable,
}: Props) => {
    const [collapsed, setCollapsed] = useState(false)
    const handleCollapse = useCallback(() => {
        setCollapsed((collapsed) => !collapsed)
    }, [])
    return (
        <div
            className={classnames('grid-block-holder', {
                'grid-block-full-width': fullWidth,
                'grid-block-swap-order': swapOrder,
                'grid-block-collapsed': collapsed,
            })}
        >
            <div className={classnames('grid-block', className)}>
                {title && collapsable && (
                    <h3>
                        <button onClick={handleCollapse} className="inline">
                            {t(title)}
                            <Triangle />
                        </button>
                    </h3>
                )}
                {title && !collapsable && <h3>{t(title)}</h3>}
                {!collapsed && children}
            </div>
        </div>
    )
}

export default GridBlock
