import { Image as ImageType } from '@zupr/types/fo'
import { imageProviderHashToUrl, Transformation } from '@zupr/utils/image'
import { ReactComponent as Picture } from '../../../svg/picture.svg'
import { useIsMobile } from '../../context/ux'

interface ImageProps {
    image: ImageType
    alt: string
    eager?: boolean
    small?: boolean
    large?: boolean
}

// load default as lazy and medium size
const Img = ({ image, alt, eager, small, large }: ImageProps) => {
    const isMobile = useIsMobile()
    let size: Transformation = 'rs:fit:620:620/enlarge:1'
    if (large && !isMobile) size = 'rs:fit:1240:1240/enlarge:1'
    if (small) size = 'rs:fit:380:380/enlarge:1'
    return (
        <img
            src={imageProviderHashToUrl(image, 'webp', size)}
            alt={alt}
            loading={eager ? 'eager' : 'lazy'}
        />
    )
}

interface ImgWithFallbackProps extends ImageProps {
    children?: React.ReactNode
}

export const ImgWithFallback = ({
    children,
    image,
    ...props
}: ImgWithFallbackProps) => {
    return (
        <figure>
            {image && <Img image={image} {...props} />}
            {!image && <Picture />}
            {children}
        </figure>
    )
}

export default Img
