import { Location } from '@zupr/types/fo'
import React from 'react'

import { imageProviderHashToUrl } from '@zupr/utils/image'
import { LazyImage } from '../components/figure'
import Carousel from '../image/carousel'
import LocationFeatures from './features'

export const sortImages = (images, preferInterior) =>
    images &&
    images.length &&
    images.sort((a, b) => {
        if (a.tag === b.tag) return a.position < b.position ? -1 : 1
        const tag = preferInterior ? 'Interior' : 'Exterior'
        return a.tag === tag ? -1 : 1
    })

interface Props {
    images: Location['images']
    title: Location['title']
    delivery: Location['delivery']
    allow_reservation: Location['allow_reservation']
    allow_pay_and_collect: Location['allow_pay_and_collect']
    features?: boolean
    interior?: boolean
    carousel?: boolean
    children?: React.ReactNode
}

const LocationImage = ({
    images,
    title,
    interior,
    carousel,
    features,
    children,
    ...props
}: Props) => {
    const sortedImages = sortImages(images, interior)
    if (carousel && sortedImages.length > 1) {
        return (
            <div className="location-image">
                <Carousel navigation images={sortedImages} alt={title} />
                {children}
                {features && <LocationFeatures className="badges" {...props} />}
            </div>
        )
    }
    return (
        <div className="location-image">
            <LazyImage
                {...props}
                url={
                    sortedImages?.[0] &&
                    imageProviderHashToUrl(
                        { id: sortedImages[0].image_provider_hash },
                        'webp',
                        'rs:fit:380:380/enlarge:1'
                    )
                }
                alt={title}
            >
                {children}
                {features && <LocationFeatures className="badges" {...props} />}
            </LazyImage>
        </div>
    )
}

export default LocationImage
