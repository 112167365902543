import { Variation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { ThemeTypeEnum } from '@zupr/types/graphql'

import GridBlock from '../../components/grid/block'
import Invite from '../../components/search/result/invite'
import ProductResult from '../../components/search/result/product'

export interface ProductsProps {
    products: List<Variation>
    theme?: ThemeTypeEnum
    limit?: number
}

const ProductsGridList = ({ products, theme, limit }: ProductsProps) => (
    <GridBlock className="grid-list grid-list-full-width grid-list-products">
        <ul>
            {products?.results.map((product, index) => (
                <ProductResult
                    theme={theme}
                    index={index}
                    key={`product.${product.id}${theme || ''}`}
                    {...product}
                />
            ))}
            {products?.results.length < (limit || 4) && <Invite />}
        </ul>
    </GridBlock>
)

export default ProductsGridList
