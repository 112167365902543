import { Image as ImageType } from '@zupr/types/fo'
import classnames from 'classnames'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import React, { useCallback, useContext, useState } from 'react'

import ModalContext from '../../../context/modal'
import Figure from '../../components/figure'
import Thumbnails from './thumbnails'

import { ReactComponent as Chevron } from '../../../../svg/chevron.svg'

import '../../../../scss/react/components/carousel.scss'
import Image from '../../components/image'

interface InteractiveCarouselProps {
    images: ImageType[]
    alt: string
    indicator?: boolean
    zoom?: boolean
    navigation?: boolean
    thumbnails?: boolean
    cover?: boolean
    startIndex?: number
}

const InteractiveCarousel = ({
    images,
    indicator,
    zoom,
    navigation,
    thumbnails,
    cover,
    startIndex,
    alt,
}: InteractiveCarouselProps) => {
    const { openModal } = useContext(ModalContext)

    const [currentSlide, setCurrentSlide] = useState(startIndex)

    const [sliderRef, slider] = useKeenSlider({
        loop: true,
        initial: currentSlide,
        slideChanged: (s) => {
            if (s.details().size <= 1) return
            if (isNaN(s.details().relativeSlide)) return
            setCurrentSlide(s.details().relativeSlide)
        },
    })

    const hasMoreThanOne = images && images.length > 1

    const handleZoom = useCallback(() => {
        openModal({
            className: 'modal-image',
            closeButton: true,
            render: () => (
                <InteractiveCarousel
                    alt={alt}
                    images={images}
                    thumbnails
                    navigation
                />
            ),
        })
    }, [images, openModal, alt])

    const handleNext = useCallback(() => {
        slider.next()
    }, [slider])

    const handlePrev = useCallback(() => {
        slider.prev()
    }, [slider])

    return (
        <div
            className={classnames('carousel-container', {
                'has-thumbnails': thumbnails && hasMoreThanOne,
            })}
        >
            <div
                ref={sliderRef as React.RefObject<HTMLDivElement>}
                className="carousel-top keen-slider"
            >
                {images.map((image, index) => (
                    <div
                        key={`keen-slide-${index}`}
                        className={classnames('keen-slider__slide', {
                            cover,
                        })}
                    >
                        {!!zoom && (
                            <button onClick={handleZoom} className="inline">
                                <Image
                                    image={image}
                                    alt={alt}
                                    large
                                    eager={index === 0}
                                />
                            </button>
                        )}
                        {!zoom && (
                            <Image
                                image={image}
                                alt={alt}
                                large
                                eager={index === 0}
                            />
                        )}
                    </div>
                ))}
                {hasMoreThanOne && slider && navigation && !thumbnails && (
                    <React.Fragment>
                        <button
                            type="button"
                            onClick={handlePrev}
                            className="carousel-left"
                        >
                            <Chevron />
                        </button>
                        <button
                            type="button"
                            onClick={handleNext}
                            className="carousel-right"
                        >
                            <Chevron />
                        </button>
                    </React.Fragment>
                )}
            </div>

            {hasMoreThanOne && (
                <React.Fragment>
                    {slider && indicator && (
                        <div className="carousel-indicator">
                            {images.map((_, i) => (
                                <span
                                    key={i}
                                    className={classnames({
                                        active: i === currentSlide,
                                    })}
                                />
                            ))}
                        </div>
                    )}

                    {slider && thumbnails && (
                        <Thumbnails
                            alt={alt}
                            cover={cover}
                            images={images}
                            next={slider.next}
                            prev={slider.prev}
                            goTo={(i) => slider.moveToSlideRelative(i, true)}
                            currentSlide={currentSlide}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

InteractiveCarousel.defaultProps = {
    startIndex: 0,
}

interface CarouselProps extends InteractiveCarouselProps {
    className?: string
    images: ImageType[]
}

const Carousel = ({ images, className, ...props }: CarouselProps) => {
    if (!images?.length) {
        return (
            <div className={className}>
                <div className="carousel-top carousel-no-slider">
                    <Figure {...props} />
                </div>
            </div>
        )
    }

    return (
        <div className={className}>
            <InteractiveCarousel {...props} images={images} />
        </div>
    )
}

Carousel.defaultProps = {
    className: 'images',
}

export default Carousel
