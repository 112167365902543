import { Location } from '@zupr/types/fo'
import { locationUrl } from '@zupr/utils/url'
import Link from 'next/link'

import Trans from '../../../../shared/components/trans'
import AddressAndHours from '../../../../shared/location/address-and-hours'
import LocationClassifications from '../../../../shared/location/classifications'
import LocationImage from '../../../../shared/location/location-image'

const LocationResult = (location: Location) => {
    const { title, opening_hours, address, number, zipcode, city, images } =
        location
    return (
        <li>
            <Link href={locationUrl(location, 'home')}>
                <a>
                    <LocationImage
                        features
                        title={title}
                        images={images}
                        {...location}
                    />
                    <div className="titles">
                        <h2>{title}</h2>
                        <LocationClassifications
                            classificationList={location.classificationList}
                        />
                    </div>
                    <div>
                        <AddressAndHours
                            address={address}
                            number={number}
                            zipcode={zipcode}
                            city={city}
                            opening_hours={opening_hours}
                        />
                        <span className="link">
                            <Trans label="View location" />
                        </span>
                    </div>
                </a>
            </Link>
        </li>
    )
}
export default LocationResult
