import React from 'react'
import Figure from '../../../shared/components/figure'
import { StoryModalLink } from './entrepreneur'

import '../../../../scss/react/frontoffice/entrepreneur.scss'

const EntrepreneursList = ({ stories }) => (
    <ul className="entrepeneurs-list">
        {stories.map((story) => (
            <li key={story.id}>
                <StoryModalLink story={story}>
                    <div className="image-holder">
                        <Figure cover url={story.photo} />
                    </div>
                    <h2>{story.owner}</h2>
                    <h3>{story.locationTitle}</h3>
                </StoryModalLink>
            </li>
        ))}
    </ul>
)

export default EntrepreneursList
