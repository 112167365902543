import React from 'react'
import Checkbox from './checkbox'
import Trans from '../../components/trans'

import '../../../../scss/react/form/check.scss'
import { Field } from '@zupr/types/form'

interface CheckFieldProps {
    field: Field
    label?: string
    children?: React.ReactNode
}

const CheckField = ({ field, label, children }: CheckFieldProps) => (
    <label className="row-label row-label-check" htmlFor={field.name}>
        <Checkbox field={field} />
        {children || <Trans label={label || field.label || field.name} />}
    </label>
)

export default CheckField
