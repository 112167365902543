import { Location } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'

import { useIsMobile } from '../../../context/ux'
import GridBlock from '../../components/grid/block'
import Invite from '../../components/search/result/invite'
import LocationResult from '../../components/search/result/location'

export interface LocationsProps {
    locations: List<Location>
}

const Locations = ({ locations }: LocationsProps) => {
    const isMobile = useIsMobile()

    // number of dummies to show
    // while loading show 4
    // if locations are done loading subtract the number locations and one spot for invite
    const numberOfDummies = Math.max(
        0,
        4 - (locations?.results.length + 1 || 0)
    )

    return (
        <GridBlock className="grid-list grid-list-full-width grid-list-locations">
            <ul>
                {locations?.results.map((location) => (
                    <LocationResult
                        key={`location.${location.id}`}
                        {...location}
                    />
                ))}
                {locations?.results.length < 4 && <Invite />}

                {!isMobile &&
                    [...Array(numberOfDummies)].map((_, i) => <li></li>)}
            </ul>
        </GridBlock>
    )
}

export default Locations
