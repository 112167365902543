import { hasPhrase, t } from '@zupr/i18n'
import { ErrorType } from '@zupr/types/graphql'
import React from 'react'

import '../../../scss/react/form/error.scss'

interface ErrorProps {
    error?: string
    context?: string
    children?: React.ReactNode
    values?: { [key: string]: any }
}

export const Error = ({ error, context, values, children }: ErrorProps) => (
    <div className="form-error">
        {children}
        {error && t(error, context, values)}
    </div>
)

interface FieldErrorsProps {
    fields: string[]
    form: any
}

export const FieldErrors = ({ form, fields }: FieldErrorsProps) => (
    <>
        {fields.map((name) => {
            const error = form.getField(name).error
            if (!error) return null
            if (typeof error === 'string') return <Error error={error} />
            return error.map((err) => <Error error={err} />)
        })}
    </>
)

interface GraphqlErrorsProps {
    errors: ErrorType[]
}

export const GraphqlErrors = ({ errors }: GraphqlErrorsProps) => {
    if (!errors) return null
    return (
        <>
            {errors.map((error) => {
                const { field, messages } = error
                return messages?.map(({ code, message, params }) => (
                    <Error
                        context="errors"
                        error={hasPhrase(code, 'errors') ? code : message}
                        values={{ field, ...params }}
                    />
                ))
            })}
        </>
    )
}
