import React from 'react'
import { Location } from '@zupr/types/fo'

interface AddressProps {
    address: Location['address']
    number: Location['number']
    zipcode: Location['zipcode']
    city: Location['city']
}

const Address = ({ address, number, zipcode, city }: AddressProps) => (
    <React.Fragment>
        {address} {number} <br />
        {zipcode} {city}
    </React.Fragment>
)

export default Address
