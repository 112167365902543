import { Location } from '@zupr/types/fo'
import React from 'react'

const LocationClassifications = ({
    classificationList,
}: {
    classificationList: Location['classificationList']
}) => {
    if (!classificationList) return null
    if (!classificationList.length) return null

    const classificationNames = classificationList
        .map(
            (classification) =>
                classification.nameSingular || classification.name
        )
        .filter((name) => !!name)
    if (!classificationList.length) return null
    return <React.Fragment>{classificationNames.join(', ')}</React.Fragment>
}

export default LocationClassifications
