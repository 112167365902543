import { useUniqueEmail } from '@zupr/hooks/fields/email'

import { Field } from '@zupr/types/form'
import Input from './input'

interface EmailProps {
    field: Field
    unique?: boolean
}

const EmailField = ({ field, unique, ...props }: EmailProps) => {
    useUniqueEmail({ field, pause: !unique })
    return (
        <Input
            {...props}
            type="email"
            autoComplete="email"
            autoCapitalize="off"
            field={field}
        />
    )
}

export default EmailField
